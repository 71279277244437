// ========================================================
// ON READY
// ========================================================
document.addEventListener('DOMContentLoaded', function() {
	$.parallaxify({
		positionProperty: 'transform',
		responsive: true,
		motionType: 'gaussian',
		useMouseMove: false,
		motionAngleX: 70,
		motionAngleY: 70,
		alphaFilter: 0.5,
		adjustBasePosition: true,
		alphaPosition: 0.9,
	});

}, false);

var scotchApp = angular.module('scotchApp', ['ngRoute', 'ngAnimate']);

scotchApp.config(function($routeProvider) {
	$routeProvider

		// routes
		.when('/', {
			templateUrl : 'pages/intro.php',
			controller  : 'homeController'
		})

		.when('/startscherm', {
			templateUrl : 'pages/startscherm.php',
			controller  : 'homeController'
		})

		.when('/inlog', {
			templateUrl : 'pages/inlog.php',
			controller  : 'homeController'
		})

		.when('/geen-abonnee', {
			templateUrl : 'pages/geen-abonnee.php',
			controller  : 'homeController'
		})

		.when('/meedoen', {
			templateUrl : 'pages/meedoen.php',
			controller  : 'homeController'
		})

		.when('/bedankt', {
			templateUrl : 'pages/bedankt.php',
			controller  : 'homeController'
		})

		.when('/wat-kan-ik-winnen', {
			templateUrl : 'pages/wat-kan-ik-winnen.php',
			controller  : 'homeController'
		})

		.when('/actievoorwaarden', {
			templateUrl : 'pages/actievoorwaarden.php',
			controller  : 'homeController'
		})
});

scotchApp.controller('homeController', function($scope) {

	$scope.$on("$routeChangeSuccess", function () {
		if (location.hash === '#/') {
			new introTitleAnimation();
		}
	})
});

var introTitleAnimation = function() {
	
	introTitleTl = new TimelineMax({
		onComplete:function() {
			setTimeout(function() {
				window.location.hash = "/startscherm";				
			}, 2000);
		}
	});

	introTitleTl

		.timeScale(1.8)

		//intro 1
		.to("#intro1 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '+=0.5')

		.to("#intro1 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro1 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro1 .intro-title-flare", 2,
			{ left: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro1 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro1 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//intro 2
		.to("#intro2 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '+=0.5')

		.to("#intro2 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro2 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro2 .intro-title-flare", 2,
			{ left: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro2 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro2 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//intro 3
		.to("#intro3 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '+=0.5')

		.to("#intro3 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro3 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro3 .intro-title-flare", 2,
			{ left: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro3 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro3 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//intro 4
		.to("#intro4 .intro-title-animation", 0.5,
			{ opacity: 1, scale: 1.3, ease: Power4.EaseInOut}, '+=0.5')

		.to("#intro4 .intro-title-animation", 1,
			{ scale: 1.3, ease: Power4.EaseInOut}, '-=0.5')


		.to("#intro4 .intro-title-flare", 0.5,
			{ opacity: 1, ease: Power4.EaseInOut})

		.to("#intro4 .intro-title-flare", 2,
			{ left: '-100%', transformOrigin: '50% 50%', ease: Power4.EaseInOut}, '-=0.5')

		.to("#intro4 .intro-title-flare", 0.5,
			{ opacity: 0, ease: Power4.EaseInOut}, '-=1')


		.to("#intro4 .intro-title-animation", 1,
			{ opacity: 1, scale: 1, ease: Power4.EaseInOut}, '-=1')

		//Intro images
		.from("#introLarge", 1,
			{ opacity: 0, scale: 0.95, ease: Power4.EaseInOut}, '+=1')

		.from("#introImage", 1,
			{ opacity: 0, y: '+=20', ease: Power4.EaseInOut})
}